//const { Toast } = require("bootstrap");   // old-school
const Toast  = require("./toast");
const { default: Swal } = require("sweetalert2");

jQuery(function() {
    $('#projectEdition').on('click', '#projectAdd .modalAction', function() {
        var form = $('#projectEdition #projectAdd #formAddProject');
        if (!form[0].checkValidity()) {
            form[0].reportValidity();
            Toast.showToastInfo('Erreur', "Veuillez remplir les champs comme indiqué", 3000);
            return;
        }
        $('#projectAdd .modal-content').addClass('loader-spinner');
        Tools.aPost(form.attr('action'), form.serialize(), null).done(function(data){
            if(data.success === true) {
                window.location = route('projects.edit', data.project_id);
            } else {
                $('#projectAdd .modal-content').removeClass('loader-spinner');
            }
        });
    });

    //Project versioning
    if ($('#versionAdd').length > 0) {
        let versionModal = document.getElementById('versionAdd');
        versionModal.addEventListener('show.bs.modal', (e) => {
            var invoker = $(e.relatedTarget);
            $(this).find('[name=project_id]').val(invoker.data('project-id'));
            $('#section_ids').selectize({
                'plugins': ['remove_button'],
                sort: 'text'
            });
        });

        $('#projectEdition').on('click', '#versionAdd .modalAction', () => {
            var form = document.querySelector('#projectEdition #versionAdd #versionForm');
            if (!form.reportValidity()) {
                return;
            }

            form = $(form);
            Tools.aPost(form.attr('action'), form.serialize(), null).done(function(data) {
                if (data.success === true && data.redirect) {
                    window.location.href = data.redirect;
                }
            });
        });
    }

    $('#projectEdition').on('click', '.btn-version', () => {
        let id = $('#version').val();
        if (id == '') {
            return;
        }
        let url = route('projects.edit', {project_id:id});
        document.location.href = url;
    });

    //Sections Management
    $('#projectEdition').on('click', '#sectionAdd .modalAction', function() {
        var form = $('#projectEdition #sectionAdd #sectionForm');
        if (!form[0].reportValidity()) { return false; }
        form.addClass('loader-spinner');
        Tools.aPost(form.attr('action'), form.serialize(), null).done(function(data) {
            if(data.success === true) {
                $('#sectionAdd').modal('hide');
                window.location.reload();
            }
            form.removeClass('loader-spinner');
        }).fail(() => { form.removeClass('loader-spinner'); });
    });

    $('#billingPeriodEdit').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);
        $(this).find('[name=projectId]').val(invoker.data('project-id'));
    });

    $('#sectionAdd').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);
        $(this).find('[name=projectId]').val(invoker.data('project-id'));
    });

    //Jobs Management
    $('#projectEdition').on('click', '#jobAdd .modalAction', function() {
        var form = $('#projectEdition #jobAdd #jobForm');
        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            method: 'POST',
            success: function(data) {
                if(data.success === true) {
                    window.location.reload();
                }
            }
        });
    });

    $('#jobAdd').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);
        $(this).find('[name=sectionId]').val(invoker.data('section-id'));
    });

    //Job Billing Management
    $('#jobAdd').on('click', '.js-add-billing', function() {
        addBilling($('#jobAdd'));
    });
    $('#jobBillingEdit').on('click', '.js-add-billing', function() {
        addBilling($('#jobBillingEdit'));
    });
    $('#jobAdd, #jobBillingEdit').on('click', '.js-remove-billing', function() {
        var parentDiv = $(this).parentsUntil('.card').parent();
        parentDiv.remove();
    });


    //Forecast Management
    $('#projectEdition').on('click', '#forecastEdit .modalAction', function() {
        var form = $('#projectEdition #forecastEdit #forecastForm');
        let elsForecast = $('.forecast-card:not(#forecast-template)');

        if (!elsForecast.length) {
            var forecastModal = bootstrap.Modal.getInstance($('#forecastEdit'));
            forecastModal.hide();
            return;
        }

        //validate Forecast
        var aryFields = {
            'jobLabel[]' : 'Merci de saisir un libellé',
        };

        let error = !validateFields(aryFields, elsForecast.find(':input'));
        if (error) {
            return;
        }

        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            method: 'POST',
            success: function(data) {
                if(data.success === true) {
                    var forecastModal = bootstrap.Modal.getInstance($('#forecastEdit'));
                    forecastModal.hide();
                }
            }
        });
    });

    $('.btn-delete').on('click', deleteProject);
});



let elFactureTemplate = null;
/**
 * @param {jQuery} parentElement
 * @param {?Object} data
 */
function addBilling(parentElement, data = {})
{
    if(!elFactureTemplate) {
        // to avoid to send the template as post we remove it
        let elFacturation = parentElement.find('#facturation-template');
        elFactureTemplate = elFacturation.clone(true, true);
        elFactureTemplate.removeAttr('id');
        elFacturation.remove();
    }

    let elFactureClone = elFactureTemplate.clone(true, true);
    parentElement.find('.facturations').append(elFactureClone);

    if(!$.isEmptyObject(data)) {
        var valuesData = {};
        for (key in data) {
            var arrayKey = key + '[]';
            valuesData[arrayKey] = data[key];
        }

        if (data.jobBillingMinimum == 1) {
            elFactureClone.find('.billing-percent-wrapper').removeClass('d-none');
        }
        Tools.fillForm(elFactureClone, valuesData);
    }
}

/**
 * @param {jQuery} evt
 */
function deleteProject(evt) {
    let project_id = $(evt.currentTarget).data('projectId');
    if (typeof project_id === 'undefined') {
        return;
    }
    let row = $(evt.currentTarget).parents('.row');
    swal.fire({
        'icon': 'info',
        'title': 'Suppression du projet',
        'text': 'Êtes-vous sûr de vouloir supprimer ce projet et toutes les données associées ?',
        'confirmButtonText': 'Oui, je suis sûr',
        'showCancelButton': true,
        'cancelButtonText': 'Non, c\'est une erreur'
    }).then((result) => {
        if (result.isConfirmed) {
            row.addClass('loader-spinner');
            Tools.aDelete(route('project.delete', {'project_id':project_id}))
                .done((data) => {
                    if (data.success == true) {
                        Toast.showToastValid('Suppression effectuée', "Rechargement de la page...", 3000);
                        window.location.reload();
                    } else {
                        row.removeClass('loader-spinner');
                    }
                }).fail(() => {
                    row.removeClass('loader-spinner');
                });
        }
    });
}
