// Todo: create composer plugin w/ auto import
(function($, window, document, undefined) {
	'use strict';

	/**
	 * editable
     *
	 * Add .editable to your container and toggle .read / .write
	 * Add .read / .write classes automaticly
	 * but you can wrap .write yoursel (compatibility w/ boostrap DOM for instance)
	 */
	$.editable = function(el, options) {

		var self = this;
		var helper = {};

		var defaults = {
			wrapperClass: '.write', // todo
			editOnClick: true, // todo

			// events
			create: function(){},
			open: function(){}

		};

		self.settings = $.extend({}, defaults, options);
		self.$context = $(el);

		/**
		 * private Function
		 * @returns {*}
		 */
		var _private = function(){
			return self;
		};

		/**
		 * public Function
		 * @returns {*}
		 */
		self.public = function(){
			return self;
		};

		/**
		 * Init the Program
		 */
		self.init = function() {

			// event:create
			self.settings.create.call(self);

            self.$context.each(function(index, el){

                let elInput = $(el).find(':input:eq(0)');
                let elWrite = $(el);
                if ($(el).is(':input')) {
                    elInput = $(el);
                    elWrite = $('<div>').addClass('write');
                }


                let inputType = elInput.attr('type');
                if (inputType == 'hidden') {
                    return;
                }

                let value = elInput.val();
                if (elInput.is('select')) {
                    value = elInput.find('option:selected').html();
                }

                if (inputType == 'checkbox') {
                    value = elInput.is(':checked') ? '<i class="fas fa-check-square"></i>' : '<i class="far fa-square"></i>';
                }

                let elRead = $('<span>').addClass('read').html(value);
                elRead.appendTo($(el).parent());

                if ($(el).is(':input')) {
                    elWrite.appendTo($(el).parent());
                    $(el).appendTo(elWrite);
                }
                else
                {
                    elWrite.appendTo($(el).parent());
                }
                elWrite.toggle();

                if (self.settings.editOnClick) {
                    // to finalize w/ callback
                    // elRead.on('click', function(){
                    //     $(this).toggle();
                    //     $(this).next('.write').toggle();
                    // });
                    // elInput.on('change', function(){
                    //     $(this).toggle();
                    //     $(this).previous('.read').toggle();
                    // })
                }

            });


			return self;
		};


		self.init();
	};

	/**
	 * Extend jQuery
	 * @param options
	 * @returns {*}
	 */
	$.fn.editable = function(options) {
		return this.each(function(i, elem) {
            new $.editable($(elem).find(':input:not(.editable .write :input,button), .write'), options);
		});

	};

}(jQuery, window, window.document));
