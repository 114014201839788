import {background} from "quill/ui/icons";


let firstToast = true
let toastCount = 1
let toastContainer
const config = {
    animation: true,
    autohide: true,
    delay: 5000 //if = 0 -> autohide = false
}

function buildToast(delay = 0, animation) {
    if (delay === 0) {
        config.autohide = false
    } else {
        config.delay = delay
    }
    config.animation = animation
    const toastDiv = document.getElementById('toast-bs-' + toastCount)
    toastCount++
    return new bootstrap.Toast(toastDiv, config)
}

/**
 * param icon syntax
 * {
 *  class : font awesome - example : "fa fa-check" - string, color : color #hex or full letter - string
 * }
 * parameter text syntax
 * {
 *  content : string, color : color #hex or full letter - string
 * }
 */
export function showToast(msg, background, title = null, icon = null, delay = 0, animation = true) {


    if (firstToast) {
        toastContainer = document.createElement('div')
        toastContainer.className = "toast-container position-fixed top-0 end-0 p-3"
        toastContainer.style.zIndex = "9999"
    }

    const toastButtonEl = document.createElement('button')
    toastButtonEl.setAttribute('data-bs-dismiss', 'toast')
    toastButtonEl.setAttribute('aria-label', 'Close')
    toastButtonEl.className = 'btn-close btn-close-white ms-auto float-end'
    toastButtonEl.type = "button"


    const toastBodyEl = document.createElement('div')

    const toastEl = document.createElement('div')
    toastEl.className = "toast gap-2 border-light "
    toastEl.id = "toast-bs-" + toastCount
    toastEl.style.zIndex = '1090'

    if (background) {
        toastEl.className += background
    }
    const toastHeaderEl = document.createElement('div')
    toastHeaderEl.className = "toast-header " + background
    toastHeaderEl.style.columnGap = '10px';

    const toastTitleEl = document.createElement('strong')
    toastTitleEl.className = "toast-title"
    toastTitleEl.style.color = title.color

    if (icon) {
        const toastIconEl = document.createElement('i')
        toastIconEl.className = "mr-1 " + icon.class
        toastIconEl.style.color = icon.color
        toastHeaderEl.appendChild(toastIconEl)
    }

    if(title) {
        toastTitleEl.appendChild(document.createTextNode("   " + title.content))
        toastBodyEl.className = "toast-body"
        toastBodyEl.style.color = msg.color
        toastBodyEl.innerHTML = msg.content.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />') //add break line
    } else {
        toastTitleEl.appendChild(document.createTextNode("   " + msg.content))
        toastTitleEl.style.color = msg.color
    }

    toastTitleEl.appendChild(toastButtonEl);

    toastHeaderEl.appendChild(toastTitleEl)
    toastHeaderEl.appendChild(toastButtonEl)
    toastEl.appendChild(toastHeaderEl)

    //!title && toastBodyEl.appendChild(toastButtonEl)
    toastEl.appendChild(toastBodyEl)

    toastContainer.appendChild(toastEl)
    if (firstToast) {
        document.body.insertBefore(toastContainer, document.body.firstChild)
        firstToast = false
    }
    const toast = buildToast(delay, animation)
    toast.show()
    setTimeout(() => {
        toast.hide()
        toastEl.remove()
        if (!toastContainer.hasChildNodes()) {
            toastContainer.remove()
            firstToast = true
        }
    }, config.delay)
}

export function showToastError(title, msg, delay) {
    showToast({content: msg, color: 'white'}, "bg-puce", title && {content: title, color: 'white'},{
        class: 'fa fa-times',
        color: 'white'
    },  delay)
}

export function showToastValid(title, msg, delay) {

    showToast({content: msg, color: 'white'}, "bg-success", title && {content: title, color: 'white'}, {
        class: 'fa fa-check',
        color: 'white'
    },  delay)

}

export function showToastWarning(title, msg, delay) {
    showToast( {content: msg, color: 'white'},"bg-warning",  title && {content: title, color: 'white'},{
        class: 'fas fa-exclamation-triangle',
        color: '#DE970B'
    }, delay)
}

export function showToastInfo(title, msg, delay) {
    showToast({content: msg, color: 'white'}, "bg-info",title && {content: title, color: 'white'},  {
        class: 'fa fa-info',
        color: 'white'
    },  delay)
}

export function showAjaxError(xhr, status, message) {
    console.log("[ERROR] Ajax\n" + xhr + "\nStatus : " + status + "\nMessage : " + message)

    const resMessage = jQuery.parseJSON()

    if (resMessage === null) {
        showToastError("Ajax Error", xhr.responseText);
    } else {
        if (resMessage.hasOwnProperty('message')) {
            showToastError("Ajax Error", responseMsg.message);
        } else {
            showToastError("Ajax Error", responseMsg);
        }
    }
}


