window._ = require('lodash'); // 1,43 Mo

try {
    window.$ = window.jQuery = require('jquery'); // < 0 Mo
    window.bootstrap = require('bootstrap'); // 0,4 Mo
} catch (e) {}

/**
 * Initialize jQuery Ajax request headers
 */
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
// window.axios = require('axios');
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

require('@fortawesome/fontawesome-free/js/all.js');

// Add fancy alert modal - < 0Mos
window.swal = require('sweetalert2');

// Add tooltip lobrary TIPPY - 0,2 Mo
window.tippy = require('tippy.js').default;

// table excel format - 1,88 Mo
import {TabulatorFull as Tabulator} from 'tabulator-tables';
window.Tabulator = Tabulator;

// use moment for better timing manipulation - 1,82 Mo
window.moment = require('moment'); // require

// fancy selector text search - 0,2 Mo
window.selectize = require('@selectize/selectize/dist/js/standalone/selectize');

// cookie getter/setter - < 0 Mo
import Cookies from 'js-cookie';
window.Cookies = Cookies;

// Lightweight framework - 0,3 Mo
import Alpine from 'alpinejs';
window.Alpine = Alpine;

// Drag and drop library - 0,3 Mo
import Sortable from 'sortablejs';
window.Sortable = Sortable;

import Quill from 'quill';
window.Quill = Quill;

window.Tagify = require('@yaireo/tagify');
require('@yaireo/tagify/dist/tagify.polyfills.min.js');

// multi select - 0,4 Mo
window.selectpicker = require('bootstrap-select/dist/js/bootstrap-select.min.js');
require('bootstrap-select/dist/js/i18n/defaults-fr_FR.min.js');

require('jquery-ui/ui/widgets/autocomplete.js');
require('jquery-ui/ui/widgets/resizable.js');
require('jquery-ui/ui/widgets/draggable.js');

require('jquery-ui-monthpicker');
require('jquery-ui-monthpicker/i18n/jquery.ui.monthpicker.fr.js');

/** Sentry **/
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.MIX_SENTRY_LARAVEL_DSN,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: process.env.MIX_APP_NAME + "@" + process.env.MIX_APP_ENV,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

// bootstrap-table
import DataTable from 'datatables.net-bs5';
window.DataTable = DataTable;
