import {Deferred} from "jquery";
//import Swal from "sweetalert2";
import {showToastError} from "./toast"
/**
 * @param {object} aryFields associative "array" field_name->error_msg
 * @param {jQuery} elsInput all input of form, for instance : $('.myForm').(':input')
 * @returns {boolean}
 */
export function validateFields(aryFields, elsInput) {
    let isValid = true;

    elsInput.each(function (index, el) {
        if (aryFields[el.name] && !$(el).val()) {
            setError($(el), aryFields[el.name]);
            isValid = false;
        }
    });

    return isValid;
}

export function setError(el, msg) {
    el.addClass('is-invalid');
    el.next('.invalid-feedback').html(msg);
    el.on('change', function () {
        $(this).removeClass('is-invalid');
    });
}

/**
 * @param {jQuery} form
 * @param {object} data
 */
export function fillForm(form, data) {
    form.find(':input:not([name=""])').each(function () {
        var inputName = $(this).attr('name');
        if (data[inputName] !== undefined) {
            if ($(this).is(':checkbox')) {
                var collapseDivName = null;
                if ($(this).data('bs-toggle') === 'collapse') {
                    collapseDivName = $(this).attr('href');
                }
                if (data[inputName] === 0 || data[inputName] === null) {
                    $(this).removeAttr('checked');
                    $(this).prop("checked", false);
                    if (collapseDivName !== null) {
                        form.find(collapseDivName).removeClass('show');
                    }
                } else {
                    $(this).attr('checked', 'checked');
                    $(this).prop("checked", true);
                    if (collapseDivName !== null) {
                        form.find(collapseDivName).addClass('show');
                    }
                }
            } else if ($(this).is(':radio')) {
                if (data[inputName] == $(this).val()) {
                    $(this).attr('checked', 'checked');
                    $(this).prop("checked", true);
                } else {
                    $(this).removeAttr('checked');
                    $(this).prop("checked", false);
                }
            } else if ($(this).is('select')) {
                $(this).find('option').each((idx, option) => {
                    if (data[inputName] == option.value) {
                        $(option).attr('selected', 'selected');
                        $(option).prop("selected", true);
                    } else {
                        $(option).removeAttr('selected');
                        $(option).prop("selected", false);
                    }
                })
            } else if ($(this).attr('type') == 'hidden') {
                if ($(this).val() == "")  {
                    $(this).val(data[inputName]).trigger('change', true);
                }
            } else {
                $(this).val(data[inputName]).trigger('change', true);
            }
        }
    });
}

export function aGet(url, precheckCallback) {
    console.log("ajax_get for : " + url);
    if (precheckCallback) {
        if (precheckCallback == false) {
            console.log("Invalid checks;");
            return $.Deferred().reject();
        }
    }
    return $.ajax({
        type: "GET",
        url: url,
        dataType: 'json'
    }).fail(function (jqXhr, status, error) {
        manageAjaxError(jqXhr.responseJSON);
    });
}

// Generic Ajax Call for POST
export function aPost(url, data, precheckCallback) {
    console.log("start ajax_post for : " + url);
    if (precheckCallback) {
        if (precheckCallback() == false) {
            console.log("Invalid precheck - return");
            return $.Deferred().reject();
        }
    } else {
        console.log("precheck Callback is empty");
    }
    return $.ajax({
        type: "POST",
        url: url,
        data: data                                                  // serializes the form's elements.
    }).fail(function (jqXhr, status, error) {
        manageAjaxError(jqXhr.responseJSON);
    });
}

// Generic Ajax Call for POST
export function aPostWithFiles(url, data, precheckCallback) {
    console.log("start ajax_post for : " + url);
    if (precheckCallback) {
        if (precheckCallback() == false) {
            console.log("Invalid precheck - return");
            return $.Deferred().reject();
        }
    } else {
        console.log("precheck Callback is empty");
    }
    return $.ajax({
        type: "POST",
        url: url,
        data: data,                                                  // serializes the form's elements.
        contentType: false,
        processData: false
    }).fail(function (jqXhr, status, error) {
        manageAjaxError(jqXhr.responseJSON);
    });
}

// Generic Ajax Call for POST
export function aPut(url, data, precheckCallback) {
    console.log("start ajax_post for : " + url);
    if (precheckCallback) {
        if (precheckCallback() == false) {
            console.log("Invalid precheck - return");
            return $.Deferred().reject();
        }
    } else {
        console.log("precheck Callback is empty");
    }
    return $.ajax({
        type: "PUT",
        url: url,
        data: data                                                  // serializes the form's elements.
    }).fail(function (jqXhr, status, error) {
        manageAjaxError(jqXhr.responseJSON);
    });
}

// Generic Ajax Call for Delete
export function aDelete(url, data, precheckCallback) {
    console.log("start ajax_delete for : " + url);
    if (precheckCallback) {
        if (precheckCallback() == false) {
            console.log("Invalid precheck - return");
            return $.Deferred().reject();
        }
    } else {
        console.log("precheck Callback is empty");
    }
    return $.ajax({
        type: "DELETE",
        url: url,
        data: data                                                  // serializes the form's elements.
    }).fail(function (jqXhr, status, error) {
        manageAjaxError(jqXhr.responseJSON);
    });
}

function manageAjaxError(data) {
    let msgError = 'Veuillez contacter un admin';
    if (data?.message && !data?.errors) {
        msgError = data?.message;
    } else if (data?.errors) {
        msgError = '';
        Object.values(data.errors).forEach((error) => {
            msgError += error.join('\n') + '\n'
        })
    }
    else if (data?.message.includes("Unauthenticated") || data?.message.includes("CSRF token mismatch")) {
        msgError = "Problème d'accès. Réessayez après avoir rechargé la page.";
    }
    showToastError("Erreur", msgError, 5000);
}

/**
 * Initialize Bootstrap tooltips
 * @param {string|undefined} selector overrides default data attribute selector
 */
export function loadTooltips(selector, options = {}) {
    if (typeof selector === 'undefined') {
        selector = '[data-bs-toggle="tooltip"]';
    }

    let tooltipTriggerList = [].slice.call(document.querySelectorAll(selector));
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        let tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
        if (tooltip != null) {
            tooltip.dispose();
        }
        return new bootstrap.Tooltip(tooltipTriggerEl, options);
    });
}

export function formatDate(date, separator = '', format = ['day', 'month', 'year']) {
    let datas = {
        day: date.getDate() >= 10 ? date.getDate() : '0' + date.getDate(),
        month: (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1),
        year: date.getFullYear(),
    }

    let formatedDate = '';
    let i = 0;
    $.each(format, (index, value) => {
        formatedDate += datas[value] + (i < 2 ? separator : '');
        i++;
    });

    return formatedDate;
}

/**
 * Copy to clipboard function with fallback if API isn't supported
 * @param {string} textToCopy
 */
export function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

export function setCurrentDate(field) {
    document.getElementById(field.id).value = new Date().toISOString().slice(0, 10);
}

export function reorderSelect() {
    const selectEls = document.body.getElementsByTagName('select')
    for (let i = 0; i < selectEls.length; i++) {
        if (selectEls[i].className.includes('sort')) {
            reorder(selectEls[i]).forEach((opt) =>
                selectEls[i].add(opt, null)
            )
        }
    }
    const selectBsEl = document.body.getElementsByClassName('selectpicker')
    for (let i = 0; i < selectBsEl.length; i++) {
        if (selectBsEl[i].className.includes('sort')) {
            reorder(selectBsEl[i]).forEach((opt) => {
                    selectBsEl[i].add(opt, null)
                }
            )
        }
    }
}


function reorder(select) {
    const options = [...select.options]
    const size = options.length
    for (let j = size; j >= 0; j--) {
        select.remove(j)
    }
    const ordered = options.sort((prev, curr) =>
        (prev.text.toLowerCase() > curr.text.toLowerCase()) ? 1 : ((curr.text.toLowerCase() > prev.text.toLowerCase()) ? -1 : 0))
    ordered.forEach((opt, index) => {
        if (!opt.value) {
            ordered.splice(index, 1)
            ordered.unshift(opt)
        }
    })
    return ordered
}


/**
 * Generate integer between limits
 * @param {integer} min
 * @param {integer} max
 * @returns {integer}
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#examples
 */
export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}


/**
 * Generic getter to form elements
 * @param {HTMLFormElement}|{JQuery} _input
 * @param {mixed} _checkbox_default_value Default value
 */
export function getInputValue(_input, _checkbox_default_value = 0) {
    let $el = $(_input);
    switch(_input.type) {
        case 'checkbox':
        case 'radio':
            return $el.prop('checked') ? $el.val() : _checkbox_default_value;
        default:
            return $el.val();
    }
}

/**
 * Generic setter to form elements
 * @param {HTMLFormElement}|{JQuery} _input
 * @param {mixed} _value
 * @return {boolean}
 */
export function setInputValue(_input, _value) {
    if (typeof _input == 'undefined') {
        return false;
    }
    if  (!_input instanceof jQuery) {
        _input = $(_input);
    }
    switch(_input.prop('type')) {
        case 'select':
        case 'select-one':
            _input.find(`option[value="${_value}"]`).attr('selected', true);  // .val(_value) doesn't work !?
            if(_input.hasClass('select-picker')){
                _input.selectpicker('destroy').selectpicker();   //patch
            }
            break;
        case 'checkbox':
        case 'radio':
            let val = _input.val();
            _input.prop('checked', (_value=='1'||_value===true||_value==val) );
            break;
        default:
            _input.val(_value);
    }
    _input.trigger('change');
    return true;
}

/**
 * Collect form elements and values according to parameters
 * @param {HTMLFormElement}|{JQuery} _form
 * @param {Boolean} _with_hidden
 * @param {mixed} _checkbox_default_value
 * @returns {Object}
 */
export function collectFormElements(_form, _with_hidden = true, _checkbox_default_value = null) {
    _form = $(_form);
    let elements = {};
    _form.find(`input${_with_hidden?'':':not([type="hidden"])'}, select${_with_hidden?'':':not([type="hidden"])'}`).each((idx, el) => {
        elements[el.name] = Tools.getInputValue(el, _checkbox_default_value);   // TODO this is buggued if 'el.name' has brackets
    });
    return elements;
}

/**
 * Indicates number of decimals
 * @param {float} value
 * @returns {integer}
 */
export function countDecimals(value) {
    value = (value+'').replace(',', '.');
    if (value.includes('.') == false) {
        return 0;
    }
    return value.split(".")[1].length;
}
