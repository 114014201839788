jQuery(function() {
    $.fn.mdbTreeview = function () {

        const $this = $(this);

        const $elements = $this.find('.treeview-colorful-element');
        const $header = $this.find('.treeview-colorful-items-header');

        $this.find('.nested').hide();

        $header.off('click');
        $header.on('click', function () {
            const $this = $(this);
            const $target = $this.siblings('.nested');
            const $pointerPlus = $this.children('.fa-plus-circle');
            const $pointerMinus = $this.children('.fa-minus-circle');

            $this.toggleClass('open');
            $pointerPlus.removeClass('fa-plus-circle');
            $pointerPlus.addClass('fa-minus-circle');
            $pointerMinus.removeClass('fa-minus-circle');
            $pointerMinus.addClass('fa-plus-circle');

            if (!$target.hasClass('active')) {
                $target.addClass('active').slideDown();
            } else {
                $target.removeClass('active').slideUp();
            }
        });

        $elements.off('click');
        $elements.on('click', function () {
            const $this = $(this);

            if ($this.prop('disabled') || $this.attr('disabled')) {
                return;
            }

            const $target = $this.siblings('.nested');
            const $pointerPlus = $this.children('.fa-circle-notch');
            const $pointerMinus = $this.children('.fa-check-circle');

            $this.toggleClass('open');
            $pointerPlus.removeClass('fa-circle-notch');
            $pointerPlus.addClass('fa-check-circle');
            $pointerMinus.removeClass('fa-check-circle');
            $pointerMinus.addClass('fa-circle-notch');

            if (!$target.hasClass('active')) {
                $target.addClass('active').slideDown();
            } else {
                $target.removeClass('active').slideUp();
            }

            if ($this.hasClass('opened')) {
                $this.removeClass('opened').trigger('listUnSelected');
            } else {
                $this.addClass('opened').trigger('listSelected');
            }
        });
    };
});
